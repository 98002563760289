import React from 'react'
import '../components/css/layout.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../components/css/terms.css'
import Seo from '../components/Seo'

const Terms = () => (
  <div>
    <Seo
      title="Digipay PH | Terms"
      description="Digipay is a digital payments and financial services platform with
a mobile wallet, an industry leading biller ecosystem,
and an interoperable backend."
      keywords="digipay.ph/terms/,Terms, Conditions, Terms and Conditions for Digipay, Services, Offered, Services to be offered."
    />
    <Header></Header>
    <div className="terms">
      <h3 align="center" className="font-weight-bold">
        TERMS AND CONDITIONS FOR DIGIPAY
      </h3>
      <h6 align="center">FSG Technology Ventures, INC.</h6>
      <h6 align="center" className="date-terms">
        Effective as of 05 October 2020
      </h6>

      <h5>DEFINITIONS</h5>
      <p>
        DIGIPAY - refers to FSG TECHNOLOGY VENTURES, INC., a corporation duly
        organized and existing under the laws of the Philippines, with office
        address at 6/F G Business Hub, 728 Shaw Blvd., Brgy. Wack-wack
        Greenhills East, Mandaluyong City.
      </p>
      <p>
        AGENT - refers to a duly registered individual or establishment set up
        by the Applicant and authorized by DIGIPAY to provide any or all of the
        Services, as defined under these Terms and Conditions.
      </p>
      <p>
        DIGIPAY PLATFORM – is a digital payments and financial services
        application with a biller/merchant ecosystem and interoperable back-end
        that may be accessed and used by the AGENT through web or mobile
        formats.
      </p>
      <p>
        PARTY or PARTIES – refers to either DIGIPAY or AGENT, or both DIGIPAY
        and AGENT, respectively.
      </p>
      <h5>I. SERVICES TO BE OFFERED</h5>
      <p>
        DIGIPAY hereby authorizes the AGENT to offer the services enumerated
        below to AGENT’s customers, as and when available in the Digipay
        Platform (hereinafter, “Services”):
      </p>
      <ol>
        <li>Mobile Money – Smart Money, PayMaya and GCash;</li>
        <li>
          Tuition Fee Collection – tuition fee payment collection of partner
          schools, universities and other learning institutions;
        </li>
        <li>
          Airtime Top-up – top-up or reload for prepaid telecommunication
          services and other prepaid services in the form of electronic load
          credits and pins;
        </li>
        <li>
          Bills Payment – payment of utility bills (i.e. electric, water,
          telecommunications, cable, etc.), credit card bills, loan
          amortizations, insurance premiums, school fees, government fees (e.g.
          SSS, Pag-Ibig, etc.) and other similar payments;
        </li>
        <li>Microinsurance – purchase of microinsurance products;</li>
        <li>
          Travel booking services – over-the-counter purchase of airline tickets
          and payment of hotel bookings; and
        </li>
        <li>
          Other related services (e.g. marketing services, communication
          services, etc.)
        </li>
      </ol>
      <p>
        DIGIPAY shall provide the AGENT access to the Digipay Platform through
        an individual log-in account, which shall include a virtual wallet or
        settlement account (“Digipay Account”), to enable the delivery of the
        Services.
      </p>

      <h5>II. AGENT’S COMMITMENTS AND RESPONSIBILITIES </h5>
      <p>
        The AGENT must adhere to the following requirements set by DIGIPAY:{' '}
      </p>
      <h6>A. Digipay Account Setup and Loading </h6>
      <ol>
        <li>
          AGENT shall pre-fund its Digipay Account to allow the AGENT to perform
          the Services.
        </li>
        <li>
          AGENT shall always maintain a Digipay Account balance that is enough
          to support the requirements of its establishment. Any transaction of
          the AGENT will not be processed if its Digipay Account balance is
          insufficient to cover the amount of the transaction.
        </li>
        <li>
          AGENT shall reload or top-up its Digipay Account through authorized
          reloading channels. When applicable, the AGENT shall pay for the
          convenience fees incurred when opting to use alternative payment
          channels.
        </li>
        <li>
          Should it desire to provide the Services at multiple
          branches/locations, the AGENT must have a Digipay Account for each
          branch/location.
        </li>
      </ol>
      <h6>B. Pricing</h6>
      <p>
        DIGIPAY reserves the right to mandate the rates, including but not
        limited to service fees, of all Services. The AGENT shall strictly
        implement the mandated pricing.
      </p>
      <h6>C. Monitoring</h6>
      <p>Agent must perform the following for every transaction:</p>
      <ol>
        <li>
          Know-Your-Customer (“KYC”) – For each transaction, the AGENT shall be
          responsible for the verification of its customers’ identity and
          documentation and conduct the necessary KYC processes, in compliance
          with Republic Act No. 9160 or the Anti-Money Laundering Act of 2001
          (“AMLA”), as amended and its Implementing Rules and Regulations, and
          shall enter accurate and complete data pertaining to the customers in
          the Digipay Platform.
        </li>
        <li>
          At the minimum, and in addition to the required information to process
          the transaction, the AGENT shall obtain the following information from
          its customers: (i) complete name, (ii) address, and (iii) mobile
          number. Such information shall be entered in the Digipay Platform to
          process the transaction.
        </li>
        <li>
          AGENT shall keep record of its transactions in the system for at least
          five (5) years (or such longer period as applicable law may require)
          from the date of transaction, including electronic cash transacted and
          the identity of the persons involved in the transaction.
        </li>
        <li>
          AGENT shall ensure compliance with the data privacy laws in the
          Philippines and secure the personal information of the customers with
          utmost diligence.
        </li>
        <li>
          AGENT shall perform any other acts as may be required under Philippine
          laws.
        </li>
      </ol>
      <h6>D. Reportorial Duties</h6>
      <p>
        AGENT is required to report in writing to DIGIPAY transactions that are
        considered suspicious/fraudulent under the AMLA within two (2) calendar
        days from the date of the relevant transaction.
      </p>
      <h6>E. Materials</h6>
      <p>
        AGENT is responsible for providing the necessary equipment, such as a
        computer, tablet or smartphone, with a data plan or Internet
        connectivity, to be used for the Services, as well as dedicated
        personnel to process its transactions using the Digipay Platform;
        provided that, the materials to be used by the AGENT and its
        branches/locations must be compliant with the minimum standards required
        by DIGIPAY.
      </p>
      <h6>F. Merchandising</h6>
      <ol>
        <li>
          The merchandising/marketing materials supplied by DIGIPAY to the AGENT
          shall be prominently displayed by the AGENT in each of its
          branches/locations in a storefront space dedicated for DIGIPAY.
        </li>
        <li>
          The AGENT and its branches/locations must display the most
          updated/latest merchandising/marketing materials issued by DIGIPAY.
        </li>
        <li>
          AGENT shall be responsible for the installation of the
          merchandising/marketing materials supplied by DIGIPAY, including any
          costs or additional materials/equipment necessary for such
          installation.
        </li>
      </ol>
      <h6>G. Promotional Cooperation</h6>
      <ol>
        <li>
          AGENT shall participate in all applicable promotional efforts
          conducted by DIGIPAY.
        </li>
        <li>
          AGENT must seek approval from DIGIPAY before implementing any
          non-DIGIPAY-initiated promotional or marketing activities related to
          the Services.
        </li>
      </ol>
      <h6>H. Liquidity and Regulatory Compliance</h6>
      <p>
        To ensure that the AGENT complies with the proper KYC verification,
        policies and procedures prescribed by DIGIPAY and relevant government
        authorities, and is always able to accept transactions, the AGENT must
        pass the mystery shopper/audit to be conducted by and in accordance with
        the standards of DIGIPAY. In the event that the AGENT fails the audit,
        the Agent shall be penalized as follows:
      </p>
      <ul>
        <li>
          First Violation: DIGIPAY shall notify the AGENT through a formal
          Memorandum;
        </li>
        <li>
          Second Violation: DIGIPAY shall provide the AGENT a Second Warning
          through a formal Memorandum; and
        </li>
        <li>
          Third Violation: DIGIPAY shall have the option to block AGENT from
          accessing the Digipay Platform or to terminate this Agreement.
        </li>
      </ul>
      <h6>I. Customer Complaints</h6>
      <ol>
        <li>
          AGENT’s front liners must be able to handle basic customer queries in
          a manner satisfactory to DIGIPAY; and
        </li>
        <li>
          More complex issues must be elevated by AGENT to DIGIPAY immediately
          for DIGIPAY’s escalation to its service provider.
        </li>
      </ol>
      <h6>J. No Exclusivity, Partnership or Agency</h6>
      <ol>
        <li>
          DIGIPAY has the option to, at any time, enter into similar arrangement
          with other persons and entities as channel partners.
        </li>
        <li>
          The AGENT may enter into similar arrangement with other service
          aggregators or persons and entities in the same or related industry or
          business, with the prior written consent of DIGIPAY; provided that,
          similar arrangements with other service aggregators shall not cause
          any disadvantage to DIGIPAY, other than the competition in the
          ordinary course of DIGIPAY’s business and other service providers’
          businesses.
        </li>
        <li>
          Nothing herein shall be construed as (i) constituting the AGENT as a
          partner, joint-venturer, agent, employee or representative of DIGIPAY
          for any transaction of whatever nature, it being that AGENT and
          DIGIPAY are separate and distinct entities, and the relation of AGENT
          and DIGIPAY to each other is as independent contractors to the other;
          or (ii) giving AGENT any right or authority to act for, or represent,
          or otherwise incur or assume any obligation or liability in behalf of
          or in the name of DIGIPAY.
        </li>
        <li>
          AGENT hereby recognizes that DIGIPAY has no employer-employee
          relationship with AGENT. AGENT is not subject to the control and
          supervision of DIGIPAY in so far as the means and methods to be
          employed, and is free to use any means and methods not contrary to
          law, regulations, and the provisions and spirit of this Agreement
          which AGENT believes will best enable promote the Services.
        </li>
        <li>
          The AGENT shall indemnify and hold DIGIPAY free and harmless from, and
          defend DIGIPAY against, any and all claims arising from the
          employee-employer relationship between the AGENT and its employees.
        </li>
      </ol>
      <h6>K. Taxes</h6>
      <p>
        All national and local taxes of whatever nature incidental to or arising
        in connection with provision by the AGENT of the Services shall be for
        its sole account.
      </p>
      <h6>L. Personal Data and Privacy</h6>
      <ol>
        <li>
          The AGENT confirms that the personal data it has provided to DIGIPAY
          shall at all times be true, correct and up to date. AGENT and/or its
          front liners further agree that the submitted personal information
          and/or sensitive personal information may be used and/or processed by
          DIGIPAY to such extent and for such time periods as DIGIPAY may deem
          necessary or convenient to provide or promote the Services, and for
          all other purposes necessary to continue the business growth of
          DIGIPAY.
        </li>
        <li>
          The Parties shall ensure that an obligation of confidentiality is
          imposed on persons authorized to process the personal data as that
          term is defined under Republic Act No. 10173, also known as the Data
          Privacy Act of 2012, its Implementing Rules and Regulations, and other
          relevant laws and issuances by the National Privacy Commission, and
          take reasonable steps to ensure the reliability and integrity of any
          its personnel who have access thereto. Further, the Parties shall
          disclose personal data or permit access to such personal data only to
          those authorized personnel with a need to know basis, and shall only
          provide such personal data to enable its authorized personnel to
          provide the services set forth in these Terms and Conditions.
        </li>
        <li>
          The AGENT confirms that it has read and understood, and accepts
          DIGIPAY’s Privacy Consent, which shall govern the processing and
          sharing of AGENT’s personal and sensitive personal information by
          DIGIPAY and its partners/affiliates, and that it shall strictly comply
          and abide by its terms and conditions, and any future amendments
          thereof.
        </li>
      </ol>
      <h5>III. DIGIPAY’S COMMITMENTS AND RESPONSIBILITIES </h5>
      <h6>A. Transaction Revenues</h6>
      <ol>
        <li>The AGENT will earn fees for successful transactions.</li>
        <li>
          Compensation for other services, such as marketing services and other
          related services, will be determined and communicated by DIGIPAY to
          the Agent prior to implementation.
        </li>
        <li>
          All service fees shall be credited to the AGENT’s Digipay Account on a
          real-time basis, except for service fees, which shall be credited
          within five (5) days after the end of the month of the transaction;
          and
        </li>
        <li>
          DIGIPAY reserves the right to change its service fee structure. Any
          change shall take effect on the date specified by DIGIPAY in the
          notice to the AGENT.
        </li>
      </ol>
      <h6>B. Marketing Support</h6>
      <p>
        DIGIPAY shall provide merchandising and marketing support to AGENT. The
        AGENT shall not reproduce, alter, modify or create derivatives of the
        merchandising/marketing materials without the consent of DIGIPAY.
      </p>
      <h6>C. Service Levels</h6>
      <ol>
        <li>
          DIGIPAY shall aim to make the system available to the AGENT at all
          times.
        </li>
        <li>
          In case of scheduled service downtime, such as system maintenance or
          system upgrade, the AGENT shall be notified in advance. As far as
          practicable, DIGIPAY shall schedule such system downtimes during
          off-peak hours to minimize the impact on AGENT’s business.
        </li>
        <li>
          In case of transaction errors or system malfunctions, DIGIPAY shall
          respond within one (1) business day from the time the problem is
          reported by the AGENT. DIGIPAY shall diagnose the problem, and
          implement the solution to address the problem or escalate the problem
          to appropriate third-party service provider to achieve final solution,
          as applicable.
        </li>
      </ol>
      <h6>D. Customer Support</h6>
      <p>
        DIGIPAY shall provide a customer support hotline which shall be
        available to the AGENT on Mondays to Fridays from 8:00am to 7:00pm, and
        on Saturdays from 8:00am to 6:00pm, excluding legal holidays and days on
        which the proper government authorities have mandated a stoppage of
        operations or a decrease in employees’ working hours, such as but not
        limited to due to community quarantine or other similar emergency
        situations.
      </p>
      <h5>IV. DISPUTES AND ERRONEOUS TRANSACTIONS</h5>
      <p>
        A. The details in the system confirmation message after every
        transaction by the AGENT and/or the entries in the system transaction
        logs for the AGENT’s Digipay Account are presumed true and correct,
        unless the AGENT notifies DIGIPAY in writing of any disputes thereon
        within three (3) business days from the date of transaction. If no
        dispute is reported within the said period, all transactions and the
        entries in the system transaction log will be considered conclusively
        true and correct.
      </p>
      <p>
        B. There shall be no reversals for transactions made through the Digipay
        Platform and erroneous transactions made by the AGENT. Disputed
        transactions shall only be credited back to the AGENT’s Digipay Account
        once the claim/dispute has been properly processed, investigated, and
        proven to be in favor of the AGENT, as determined by DIGIPAY. Only
        DIGIPAY can effect transaction reversals or credit back of transaction
        value to the AGENT’s Digipay Account.
      </p>
      <p>
        C. DIGIPAY shall have the right, within thirty (30) calendar days from
        the conclusion of each transaction by the AGENT, to review and/or make
        the necessary correction or reconciliation to AGENT's Digipay Account in
        case of any error in debiting, crediting, or mis-posting of any amount
        (any and all of which shall be referred to as “Amounts Due”) for
        whatever cause such as but not limited to system, facility, employee,
        third-party, or communication error. AGENT hereby agrees and undertakes
        to immediately return to DIGIPAY the Amounts Due without need of notice
        or demand. For this purpose, AGENT irrevocably authorizes DIGIPAY to
        effect the reconciliation and necessary debiting of funds from the
        AGENT's Digipay Account to the extent of the Amounts Due, without need
        of further act and deed, and without DIGIPAY incurring any liability as
        a consequence thereof. The authority granted herein is coupled with
        interest and may not be revoked by the AGENT without the prior written
        consent of DIGIPAY. DIGIPAY shall notify the AGENT of any correction or
        reconciliation done, as well as the justifications therefor.
      </p>
      <p>
        D. This Section is without prejudice to the exercise by DIGIPAY of the
        legal remedies to which it may entitled to under the law and this
        Agreement, including but not limited to immediate cancellation or
        suspension of the AGENT's DIGIPAY Account. Pending full recovery or
        collection by DIGIPAY of the Amounts Due, the AGENT shall hold the said
        amount in trust for DIGIPAY.
      </p>
      <h5>V. WARRANTIES AND LIMITATION OF LIABILITIES</h5>
      <p>
        The Parties warrant that: (i) the persons signing in behalf of each
        Party has full power and authority to bind the Party it represents, (ii)
        all appropriate and necessary corporate and legal actions have been
        taken to authorize the execution, delivery and performance of these
        Terms and Conditions; (iii) these Terms and Conditions constitutes their
        legal, valid and binding obligations, enforceable in accordance with its
        terms, and (iv) there is no provision in each Party’s Articles of
        Incorporation and By-Laws or any statute, rule or regulation, or any
        judgment, decree or order of any court or agency applicable to either
        Party which would be contravened by the execution and delivery of these
        Terms and Conditions or by the performance of any provision, condition,
        covenant or other term thereof.
      </p>
      <h6>B. Disclaimer of Warranties</h6>
      <ol>
        <li>
          DIGIPAY makes no warranty, express or implied, regarding its
          performance of or the Services offered hereunder.
        </li>
        <li>
          The Services are offered by DIGIPAY on an “AS IS” and “AS AVAILABLE”
          basis without warranties of any kind. DIGIPAY makes no warranty, among
          others: (i) as to the content, quality or accuracy of data or
          information provided by DIGIPAY hereunder or received or transmitted
          using the system; (ii) as to any service or product obtained using the
          system; (iii) that the systems (or services) will be uninterrupted or
          error-free; or (iv) that any particular result or information will be
          obtained.
        </li>
        <li>
          DIGIPAY does not provide any service level guarantees in so far as
          third-party proprietary platforms are concerned. It will, however,
          exert reasonable effort to forward AGENT’s concerns to the appropriate
          third-party for resolution.
        </li>
      </ol>
      <h6>C. Indemnification</h6>
      <ol>
        <li>
          The AGENT agrees to indemnify, defend and hold harmless DIGIPAY, its
          subsidiaries and affiliates, and their directors, officers, employees
          and agents,from and against any loss, damage, liability, costs and
          expenses, including reasonable attorney’s fees, arising out of or
          based on: (i) breach of, or failure to perform, any obligation of
          AGENT contained in these Terms and Conditions; or (ii) the negligence
          or misconduct of AGENT.
          <br />
          If, for any reason, DIGIPAY is made to pay such damages, losses,
          liabilities, obligations, and claims, AGENT shall reimburse DIGIPAY
          for any and all payments that it may take, as well as all expenses and
          costs, including but not limited to litigation expenses and legal fees
          that may be incurred by DIGIPAY in connection therewith.
        </li>
        <li>
          In no event shall DIGIPAY be liable for any indirect, special,
          incidental or consequential damages under these Terms and Conditions,
          including, without limitation, loss of profits, revenue, data or use,
          even if DIGIPAY has been advised of the possibility of such damages.
          DIGIPAY’s liability for damages under these Terms and Conditions shall
          not exceed the amount of the service fees generated by DIGIPAY from
          the AGENT during the last twelve (12) months from the occurrence of
          the event that caused the damage.
        </li>
      </ol>
      <h5> VI. EFFECTIVITY AND TERMINATION</h5>
      <p>
        A. These Terms and Conditions shall become effective on the date hereof
        and will remain to be effective for a period of three (3) years from
        such date, unless earlier terminated by either Party as provided for
        under these Terms and Conditions, or by mutual consent of the Parties in
        writing.
      </p>
      <p>
        B. Either Party may terminate these Terms and Conditions in case of a
        breach by the other Party (“the Defaulting Party”) of any of the
        provisions of these Terms and Conditions, provided that such Defaulting
        Party has failed to remedy such breach within thirty (30) days from
        written notice thereof, in which case, the termination will become
        effective upon the lapse of such thirty (30)- day period.
      </p>
      <p>
        For purposes of this Section, a Party shall be not be considered in
        breach of or in default under these Terms and Conditions on account of,
        and shall not be liable to the other Party for, any delay or failure to
        perform its obligations hereunder by reason of fire, earthquake, flood,
        explosion, strike, riot, war, terrorism, or similar event beyond that
        Party’s reasonable control (each a “Force Majeure Event”); provided,
        however, if a Force Majeure Event occurs, the affected Party shall, as
        soon as practicable:
      </p>
      <ol>
        <li>
          Notify the other Party of the Force Majeure Event and its impact on
          performance under this Agreement; and
        </li>
        <li>
          Use reasonable efforts to resolve any issues resulting from the Force
          Majeure Event and perform its obligations hereunder.
        </li>
      </ol>
      <p>
        Notwithstanding the foregoing, either Party may terminate these Terms
        and Conditions if a Force Majeure Event adversely affects the other
        Party’s performance of its obligations hereunder, and continues for more
        than thirty (30) days. Termination shall be without prejudice to any
        right of any Party which may have accrued prior to the date of
        termination.
      </p>
      <p>
        C. Either Party may terminate these Terms and Conditions without cause
        by giving the other Party written notice of at least thirty (30) days
        prior to intended date of termination (“Notice Period”).
      </p>
      <p>
        D. Upon the expiration of the Notice Period, (i) AGENT shall return to
        DIGIPAY all related merchandising/marketing materials, unused supplies,
        and equipment provided by DIGIPAY pursuant to these Terms and
        Conditions; (ii) DIGIPAY shall return to AGENT the balance of the
        AGENT’s Digipay Account within thirty (30) business days from receipt by
        DIGIPAY of all such merchandising/marketing materials, unused supplies,
        and equipment provided by DIGIPAY pursuant to these Terms aND
        Conditions. DIGIPAY shall have the sole option to deduct from the
        balance of the AGENT’s Digipay Account anY amount to cover any
        unreturned or damaged materials, supplies or equipment.
      </p>
      <p>
        E. The termination of these Terms and Conditions shall be without
        prejudice to any rights that may accrue to the Parties prior to such
        termination. Neither shall such termination prejudice the Parties’
        rights under certain provisions of these Terms and Conditions, which are
        intended to survive such termination.
      </p>
      <h5>VII. CONFIDENTIALITY</h5>
      <p>
        Technical and commercial information, including all personal information
        of the clients, and other information which DIGIPAY may disclose to
        AGENT shall be considered confidential, and AGENT shall:
      </p>
      <p>
        A. Hold the information in confidence and restrict disclosure of the
        information solely to its employees or representatives on a need to know
        basis under terms of confidentiality equivalent to those contained
        herein and shall not disclose it to any other parties; and
      </p>
      <p>
        B. Advise its employees of their obligations with respect to the
        confidential information.
      </p>
      <h5>VIII. ASSIGNMENT</h5>
      <p>
        AGENT shall not assign or transfer its rights and obligations under
        these Terms and Conditions to a third party or its subsidiary without
        the prior written consent of DIGIPAY. The AGENT should also notify
        DIGIPAY in writing in case of a change in ownership. AGENT shall not
        lease out, lend or deliver possession over the materials or items
        provided by DIGIPAY under these Terms and Conditions to third parties
        without the prior written consent of DIGIPAY.
      </p>
      <p>
        DIGIPAY may assign or transfer its rights and obligations under these
        Terms and Conditions to a third party or its subsidiary without prior
        written consent from the Agent; provided DIGIPAY notifies AGENT, in
        writing, of the assignment or transfer.
      </p>
      <h5>IX. AMENDMENT; WAIVERS</h5>
      <p>
        DIGIPAY reserves the right to amend revise, or modify any provision of
        these Terms and Conditions at any time and for whatever reason as it may
        deem proper, without need to provide prior notice to the AGENT. It is
        the AGENT’s responsibility to regularly check any changes to these Terms
        and Conditions at the following website: https://www.digipay.ph/terms.
        The AGENT’s continued use of the Digital Platform after any such changes
        constitutes acceptance of the new Terms and Conditions. Failure to
        notify DIGIPAY of AGENT’s intention to terminate its account shall be
        construed as acceptance by AGENT of the amendments to these Terms and
        Conditions.
      </p>
      <p>
        No failure to exercise nor any delay in exercising any right or remedy
        under these Terms and Conditions on either Party’s part shall operate as
        a waiver thereof, nor shall any single or partial exercise of any right
        or remedy prevent any further or other exercise thereof of any other
        rights or remedies. The rights and remedies herein provided are
        cumulative and not exclusive of any provided by law. Any waiver of any
        Party’s rights, powers, privileges or remedies must be in writing and
        signed by that Party, and any such waiver given by a Party shall only
        relate to the particular event for which it is given.
      </p>
      <h5>X. NOTICES</h5>
      <p>
        Any notice required to be served under these Terms and Conditions by
        DIGIPAY to the AGENT shall be sufficiently served if forwarded by email,
        registered mail or by personal delivery at the addresses indicated in
        the application form submitted by the AGENT.
      </p>
      <p>
        Any notice required to be served under these Terms and Conditions by the
        AGENT to DIGIPAY shall be sufficiently served if forwarded by email,
        registered mail or by personal delivery or left at the addresses below:
      </p>
      <p>FSG Technology Ventures, Inc. </p>
      <p>
        {' '}
        6/F G Business Hub
        <br />
        728 Shaw Blvd., Brgy. Wack-wack Greenhills East <br />
        Mandaluyong City, Philippines 1552
        <br />
        Philippines 1634
      </p>
      <p>Email: info@digipay.ph</p>
      <p>
        All notices shall be deemed duly given on the date of receipt, if
        personally delivered; the date fifteen (15) days after posting, if
        mailed; or receipt of transmission, if by facsimile or e-mail. Either
        Party may change its address for purposes hereof by giving notice to the
        other Party.
      </p>
      <h5>XI. AGREEMENT</h5>
      <p>The AGENT agrees to be bound by these Terms and Conditions.</p>
      <h5>XII. GOVERNING LAW; EXCLUSIVE VENUE</h5>
      <p>
        This Agreement shall be governed by the laws of the Republic of the
        Philippines. In the event that litigation results from or arises out of
        these Terms and Conditions or the performance of the provisions thereof,
        the prevailing Party shall be entitled to reimbursement from the other
        Party of reasonable attorneys’ fees, court costs, and all other
        expenses, whether or not imposed by the court as costs, in addition to
        any other relief to which the prevailing Party may be entitled.
      </p>
      <p>
        Any dispute arising between the Parties hereto in respect of the
        interpretation of these Terms and Conditions as well as the performance
        of obligations hereunder shall be settled amicably by mutual
        consultations as far as practicable. In the event of any suit arising
        from these Terms and Conditions, the same shall be brought exclusively
        in the appropriate courts of Mandaluyong City, Philippines, to the
        exclusion of other courts.
      </p>
      <h5>XIII. SEVERABILITY</h5>
      <p>
        Should any portion of this Agreement be declared by any court of law as
        illegal, invalid or ineffective, the portions not so declared shall
        remain effective and valid and binding on the parties. The parties agree
        to replace any invalid provision with a valid provision which most
        closely approximates the intent and economic effect if the invalid
        provision.
      </p>
    </div>
    <Footer></Footer>
  </div>
)
export default Terms
